/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useRef, useState, useEffect } from "react"
import "swiper/css/bundle"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { Swiper, SwiperSlide } from "swiper/react"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import BgImage from "../../../assets/images/banner.png"
import { Pagination, Navigation } from "swiper"
import Button from "../../Elements/Button"
import { navigate } from "gatsby"
import Skeleton from "../../Skeleton"
import postscribe from "postscribe"
import { parseHTML } from "../../../helpers/GeneralHelper"
import { isSSR } from "../../../helpers/RendererHelper"
const Slide = props => {
  return <SwiperSlide>{props.children}</SwiperSlide>
}
function BannerSlider(props) {
  const [rand, setRand] = useState(Math.random(100000, 999999))
  useEffect(() => {
    if (props.items.length && !isSSR) {
      props.items.map((item, k) => {
        if (item.code_snippet !== null && item.code_snippet !== "") {
          postscribe(
            `#-o2o__adspco-heroCarousel-P-${k}-${rand}`,
            `${item.code_snippet}`
          )
        }
      })
    }
  }, [props.items])

  if (props.items.length === 0) {
    return null
  }
  return (
    <div className="container lg:mt-8 mb:3 lg:mb-8 max-h-[276px] overflow-hidden rounded-lg">
      <div className="px-[15px] md:px-0 mx-auto">
        <div
          className={`rounded-[8px] ${
            false === true && `h-[460px]`
          } overflow-hidden relative bg-[#F7f7f7]`}
        >
          <Swiper
            pagination={{
              type: "progressbar",
            }}
            navigation={false}
            modules={[Pagination, Navigation]}
            rewind={true}
            className="mySwiper"
          >
            {props.items.map((item, k) => {
              return (
                <SwiperSlide>
                  {!isSSR &&
                  item.code_snippet !== null &&
                  item.code_snippet !== "" ? (
                    <div id={`-o2o__adspco-heroCarousel-P-${k}-${rand}`} />
                  ) : (
                    <>
                      <div
                        className={`${
                          false === true ? `h-[460px]` : `h-full`
                        } lg:grid lg:grid-cols-2 lg:gap-4 relative`}
                        css={css`
                          background-image: url(${item.image});
                          background-size: cover;
                          background-position: right center;
                        `}
                      >
                        <div
                          css={css`
                            content: "";
                            background: linear-gradient(
                              69deg,
                              #222222 47.11%,
                              rgba(34, 34, 34, 0.4) 100%
                            );
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                          `}
                        />

                        <div className="px-[15px] lg:px-[90px] pb-[30px] z-2 relative flex justify-center flex-col h-full">
                          <div className="lg:self-start">
                            <h1
                              className="text-strong-35"
                              css={css`
                                &&& {
                                  color: #fff;
                                }
                              `}
                            >
                              {item.title}
                            </h1>
                            <p
                              className="mt-4 text-reg-16"
                              css={css`
                                &&& {
                                  color: #fff;
                                }
                              `}
                            >
                              {parseHTML(item.body)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default BannerSlider
