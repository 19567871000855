import React, { useContext } from "react"
import Modal from "./Modal/Modal"
import DynamicForm from "./Forms/DynamicForm/DynamicForm"
import StateContext from "../context/state/StateContext"

const CreateAddressModal = ({
  title,
  openCreate,
  closeModal,
  onSave,
  formData,
  suppData,
  onExtraClick,
  savedStoreId,
}) => {
  const { state } = useContext(StateContext)

  return (
    <Modal title={title} isOpen={openCreate} closeModal={closeModal}>
      {formData !== null && (
        <DynamicForm
          onSave={onSave}
          addNew={true}
          data={
            formData
          }
          token={state.token}
          name="address"
          bundle="default"
          dynamicFields={[
            {
              subject: "field_store",
              controller: "field_address_type",
              if_equals: ["store"],
            },
            {
              subject: "field_location",
              controller: "field_address_type",
              if_equals: ["other", "locker", "home", "office"],
            },
            {
              subject: "field_first_name",
              controller: "field_address_type",
              if_equals: ["other", "locker", "home", "office", "store"],
            },
            {
              subject: "field_last_name",
              controller: "field_address_type",
              if_equals: ["other", "locker", "home", "office", "store"],
            },
            {
              subject: "field_company_name",
              controller: "field_address_type",
              if_equals: ["other", "locker", "home", "office"],
            },
          ]}
          suppData={suppData}
          onExtraClick={onExtraClick}
          savedStoreId={savedStoreId}
        />
      )}
    </Modal>
  )
}

export default CreateAddressModal
