/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import { Table, Tr, Td } from "./TableElements"
import FloatingCheckbox from "../Elements/Form/FloatingCheckbox"
import NotificationContext from "../../context/Notification/NotificationContext"
import HideIcon from "../../../src/assets/icons/hide-eye.svg"
import UnhideIcon from "../../../src/assets/icons/unhide-eye.svg"
import {
  OfficeBuildingIcon,
  PhoneIcon,
  RefreshIcon,
  UserIcon,
} from "@heroicons/react/solid"
import {
  getMyListing,
  getUserAddressBookListing,
  hideUnhideAddressBook,
} from "../../helpers/DrupalHelper"
import { StarIcon } from "@heroicons/react/outline"
import Button from "../Elements/Button"
import { FloatingOption } from "../../pages/dashboard/address-book"
import ButtonEdit from "../Elements/ButtonEdit"
import ButtonDelete from "../Elements/ButtonDelete"

const MyAddressesTable = ({
  data,
  state,
  setState,
  setEdit,
  setDelete,
  setSuppData,
  suppData,
  setList,
  defaultAddr,
  onEditClick,
  onDeleteClick,
  setAsDefault,
  // setModifiedData,
  // modifiedData,
  isOptionShow,
  setIsOptionShow,
  selectedItems,
  setSelectedItems,
}) => {
  // const [modifiedData, setModifiedData] = useState(
  //   data.map(item => {
  //     return {
  //       ...item,
  //       checked: false,
  //     }
  //   })
  // )

  const [hiddenStateLoader, setHiddenStateLoader] = useState(false)
  const { toggleNotification } = useContext(NotificationContext)
  const onHideUnhideClick = async (v, hiddenState, index) => {
    console.log({ index })
    setHiddenStateLoader(true)
    const hideStateReq = await hideUnhideAddressBook(state.token, v)
    const datas = await getMyListing(state.token, "address", "default", "all")
    let supplementaryData = await getUserAddressBookListing(state.token)
    setSuppData(supplementaryData)
    setList(datas)
    setSuppData(supplementaryData)

    console.log(hideStateReq)
    setHiddenStateLoader(false)
    if (hideStateReq.status === 200) {
      setHiddenStateLoader(false)
      toggleNotification({
        content:
          hiddenState === "0"
            ? "Address Hidden From Cart Sucessfully"
            : "Address Unhidden From Cart Sucessfully",
      })
    } else {
      setHiddenStateLoader(false)
      toggleNotification({
        content: "That failed. Sorry about that.",
        error: true,
      })
    }
  }

  // const isOptionShow = modifiedData.some(item => item.checked === true)
  console.log(data)

  return (
    <div className="relative">
      <Table
        columns={[
          // "",
          // <FloatingCheckbox
          //   hookForm={false}
          //   gray
          //   register
          //   onChange={e => {
          //     setModifiedData(
          //       modifiedData?.map(item => {
          //         return {
          //           ...item,
          //           checked: e.target.checked,
          //         }
          //       })
          //     )
          //   }}
          //   className={"!w-8 !h-8 !ml-0 cursor-pointer "}
          //   value={modifiedData?.every(item => item.checked === true)}
          // />,
          "Title",
          "Type",
          "Contact Name",
          "Company Name",
          "Phone",
          "Location",
          // "Mailing Lists",
          "Visible in Cart",
          "Actions",
        ]}
        sm
        bold
        className={"!whitespace-normal"}
      >
        {data?.map((item, k) => {
          const supp = suppData.filter(
            f => parseInt(f.id) === parseInt(item.id)
          )[0]
          return (
            <Tr key={k}>
              {/* <Td>
                <FloatingCheckbox
                  hookForm={false}
                  gray
                  register
                  onChange={e => {
                    setIsOptionShow(e.target.checked)
                    setSelectedItems(item)
                  }}
                  // onChange={e =>
                  //   setModifiedData(
                  //     modifiedData?.map((v, i) => {
                  //       if (i === k) {
                  //         return {
                  //           ...v,
                  //           checked: e.target.checked,
                  //         }
                  //       }
                  //       return v
                  //     })
                  //   )
                  // }
                  className={"!w-8 !h-8 !ml-0 cursor-pointer"}
                  value={isOptionShow && selectedItems?.id === item?.id}
                />
              </Td> */}
              <Td>
                <div className="w-3/5">
                  <span className="text-med-14">{item.name}</span>
                  {parseInt(item.id) === parseInt(defaultAddr) ? (
                    <span className="flex items-center text-reg-14 green">
                      <StarIcon className="w-3 h-3 mr-1" /> Default address
                    </span>
                  ) : (
                    <span
                      className="transition-all text-reg-14 w-fit hover:text-[#009d4f] hover hover-green hover-lh-1 hover-thin cursor-pointer !flex items-center"
                      onClick={() => setAsDefault(item.id)}
                    >
                      Set as Default
                    </span>
                  )}
                </div>
              </Td>
              <Td>
                <div className="min-w-[30px] w-3/5">
                  <span className="text-med-14"> {item.type}</span>
                </div>
              </Td>
              <Td className="">
                <div className="w-[30px] ">
                  <div className="text-med-14 w-fit">
                    <div className="flex flex-col space-y-2 xl:flex-row xl:space-y-0 xl:space-x-2 justify-start items-start">
                      <div className="w-fit">{item.first_name}</div>
                      <div className="w-fit"> {item.last_name}</div>
                    </div>
                    {/* <div className="flex flex-col w-fit">
                      <div className="w-fit">{item.first_name}</div>
                      <div className="w-fit"> {item.last_name}</div>
                    </div> */}
                  </div>
                </div>
              </Td>
              <Td>
                <span className="text-med-14"> {item.company_name}</span>
              </Td>
              <Td className="!whitespace-normal">
                <span className="text-med-14"> {item.phone}</span>
              </Td>
              <Td className="!whitespace-normal">
                <div className="min-w-[105px]">
                  {item.field_store?.length > 0 && (
                    <span className="text-med-14 break-words">
                      {item.field_store?.length > 0 &&
                        item.field_store[0].title}
                    </span>
                  )}
                  {item.location?.length > 0 && (
                    <span className="text-med-14 break-words">
                      {item.location?.length > 0 && item.location}{" "}
                    </span>
                  )}
                </div>
              </Td>
              {/* <Td>
              <span className="text-med-14"> item.mailingList</span>
            </Td> */}
              <Td className="!whitespace-normal ">
                <div className="">
                  <span className="text-med-14">
                    {" "}
                    {item.hidden_from_cart !== true ? (
                      <span className="text-med-14">Yes</span>
                    ) : (
                      <span className="text-med-14">No</span>
                    )}
                  </span>
                </div>
              </Td>
              <Td>
                <div className="flex flex-col space-y-2 xl:flex-row xl:space-y-0 xl:space-x-2 justify-start items-start">
                  <div>
                    <ButtonEdit
                      text="Edit"
                      onClick={() => {
                        onEditClick(item.id, item.bundle)
                        console.log("clciked edit")
                      }}
                    />
                  </div>
                  <div>
                    <ButtonDelete
                      text="Delete"
                      onClick={() => onDeleteClick(item.id, item.bundle)}
                    />
                  </div>
                </div>
              </Td>
            </Tr>
          )
        })}
      </Table>
      {isOptionShow && (
        <div className="sticky block max-md:hidden bottom-2">
          <FloatingOption selectedItems={selectedItems} />
        </div>
      )}
    </div>
  )
}

export default MyAddressesTable
