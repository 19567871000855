/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"

export default function Input(props) {
  const {
    onChange,
    onBlur,
    onIconClick,
    icon,
    label,
    placeholder,
    name,
    sm,
    defaultValue,
    showPointer,
    onKeyDown,
    value,
  } = props
  console.log("value", value)
  return (
    <div>
      {label && (
        <label
          htmlFor={props.name ? name : "input-" + Math.random(90000, 10000)}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          type="text"
          name={props.name ? name : "input-" + Math.random(90000, 10000)}
          id={props.name ? name : "input-" + Math.random(90000, 10000)}
          className={`focus:border-[#222] focus:ring-0 block w-full pr-10 border-1 border-[#EBEBEB] rounded-[8px] ${
            sm ? `h-[50px] text-[15px]` : `h-[60px]`
          }`}
          placeholder={placeholder ? placeholder : ""}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
          onKeyDown={onKeyDown}
          value={value && value}
        />
        {icon && (
          <div
            className={`absolute inset-y-0 right-0 pr-3 flex items-center ${
              showPointer ? "cursor-pointer" : "pointer-events-none"
            }`}
            onClick={() => {
              onIconClick()
            }}
          >
            {icon}
          </div>
        )}
      </div>
    </div>
  )
}
