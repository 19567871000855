/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import Input from "./Input"
import SearchIcon from "../../assets/icons/search.svg"
function SearchInput(props) {
  return (
    <Input
      // placeholder={placeholder}
      icon={<SearchIcon />}
      // onChange={onChange}
      {...props}
    />
  )
}

export default SearchInput
